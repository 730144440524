export const STATES = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
]
export const CARE_PROCESS_MODELS = [
  {
    groupName: 'Behavioral Health CPMs and Related Tools',
    options: [
      {
        name: 'ADHD CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=51078888',
      },
      {
        name: 'ADHD CPM Reference List (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529309571',
      },
      {
        name: 'Bipolar Disorder CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=520407484',
      },
      {
        name: 'Chronic Insomnia CPM',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530439962',
        subOptions: [
          {
            name: 'Insomnia Pharmacotherapy',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530439964',
          },
          {
            name: 'Prescribing Sedatives and Hypnotics for Insomnia',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530439966',
          },
        ],
      },
      {
        name: 'Depression CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=51061767',
      },
      {
        name: 'Eating Disorders CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522882792',
      },
      {
        name: 'First-episode psychosis in adults: Workup instructions (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529652135',
      },
      {
        name: 'Ketamine administration - outpatient (Internal only)',
        value: 'https://ckr.ihc.com/ckr/Dcmnt?ncid=529769557',
      },
      {
        name: 'Ketamine administration - inpatient (Internal only)',
        value: 'https://ckr.ihc.com/ckr/Dcmnt?ncid=529769552',
      },
      {
        name: 'Ketamine administration - community or home (Internal only)',
        value: 'https://ckr.ihc.com/ckr/Dcmnt?ncid=529769545',
      },
      {
        name: 'Mental Health Integration (MHI) CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=51080953',
      },
      {
        name: 'Opioid Tapering CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529635092',
      },
      {
        name: 'Pediatric Traumatic Stress CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529796906',
      },
      {
        name: 'Pediatric Traumatic Stress in Primary Care Settings Road Map (0 to 5 Years Old)',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529796081',
      },
      {
        name: 'Pediatric Traumatic Stress in Primary Care Settings Road Map (6 to 18 Years Old)',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529796052',
      },
      {
        name: 'Pediatric Traumatic Stress in Child Advocacy Centers (CAC) Road Map (6 to 18 Years Old)',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529796073',
      },
      {
        name: 'Pharmacogenomics (PGx) Testing : Pediatric Psychiatry clinical guideline (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856590',
      },
      {
        name: 'Prevention of lethal opioid overdose in the community',
        value:
          'https://www.uptodate.com/contents/prevention-of-lethal-opioid-overdose-in-the-community?search=naloxone&sectionRank=1&usage_type=default&anchor=H178806&source=machineLearning&selectedTitle=3~149&display_rank=1#H178806',
      },
      {
        name: 'Social Determinants of Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529732182',
      },
      {
        name: 'Substance Use Disorder (SUD) CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=526758352',
      },
      {
        name: 'Suicide Prevention CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=526742474',
      },
    ],
  },
  {
    groupName: 'Cardiovascular CPMs and Related Tools',
    options: [
      {
        name: `A Physician's Guide to Imaging Radiation Exposure CPM`,
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521190311',
      },
      {
        name: 'ACE Inhibitors and ARBs for Patients with Heart Failure',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525935617',
      },
      {
        name: 'ACS in the ED and Inpatients (Canyons & Desert Regions)',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530420539',
      },
      {
        name: 'Acute Coronary Syndrome (ACS) Guidelines',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529651242',
      },
      {
        name: 'Aldosterone Receptor Antagonists for Patients with Heart Failure Guideline (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=525935618',
      },
      {
        name: 'Anthracyclines: Managing cardiotoxicity in cancer patients (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529731833',
      },
      {
        name: 'Antiplatelet and Anticoagulant Guidelines for Coronary Stent Placement',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521052710',
      },
      {
        name: 'ARNI for Patients with Heart Failure Guideline (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529661323',
      },
      {
        name: 'Atrial Fibrillation CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522592305',
      },
      {
        name: 'Bivalirudin - Pediatrics: guideline for clinical pharmacists',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856067',
      },
      {
        name: `Cardio-Oncology Cancer Survivorship Guidelines: A plan for managing
        patients' cardiovascular health following cancer treatment (Internal only)`,
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529731836',
      },
      {
        name: 'Cumulative Radiation Exposure and Your Patient',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521548897',
      },
      {
        name: 'Fetal Heart Screening for Congenital Heart Disease CPM',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856679',
      },
      {
        name: 'HCN Channel Blockers for Patients with Heart Failure Guideline (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529661299',
      },
      {
        name: 'High Blood Pressure Management CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=51061853',
      },
      {
        name: 'Obtaining an Accurate Blood Pressure Measurement (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=523109652',
      },
      {
        name: 'Outdoor Air Quality and Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=527926681',
      },
      {
        name: 'SGLT2 (Sodium-Glucose Transport Protein) Inhibitors/Gliflozins in Heart Failure clinical guideline',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856586',
      },
      {
        name: 'Social Determinants of Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529732182',
      },
      {
        name: 'Stroke: Hospital Care and Rehabilitation for Adult Stroke and TIA Patients CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529339925',
      },
      {
        name: 'Stroke: Hospital Care and Rehabilitation CPM Reference List',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529306199',
      },
      {
        name: 'Trastuzumab (Herceptin): Managing cardiotoxicity in cancer patients (Internal only)​',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529731834',
      },
      {
        name: 'Venus Thromboembolism (VTE) CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529597630',
      },
    ],
  },
  {
    groupName: 'Collaborative Pharmacy Models',
    options: [
      {
        name: 'Bivalirudin - Pediatrics: guideline for clincial pharmacists',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856067',
      },
      {
        name: 'Collaborative Practice Resources',
        value:
          'https://intermountainhealth.sharepoint.com/sites/MedicationGuidelines/SitePages/CollaborativePracticeResources.aspx',
      },
      {
        name: 'Opioid Use in the Lactating Mother CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520732469',
      },
      {
        name: 'SGLT2 (Sodium-Glucose Transport Protein) Inhibitors/Gliflozins in Heart Failure clinical guideline',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856586',
      },
      {
        name: 'Urinary Tract Infections (UTI) - Adults CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529723399',
      },
    ],
  },
  {
    groupName: 'Imaging Service CPMs and Related Tools',
    options: [
      {
        name: `A Physician's Guide to Imaging Radiation Exposure CPM`,
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521190311',
      },
      {
        name: `Acute Traumatic Intracranial Hemorrhage in Adults: The Modified Brain Injury Guidelines (mBIG)`,
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530445522',
      },
      {
        name: 'Choosing Wisely at Intermountain - Adult (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=522448817',
      },
      {
        name: 'Choosing Wisely at Intermountain - Pediatric (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=522448814',
      },
      {
        name: 'Cumulative Radiation Exposure and Your Patient',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521548897',
      },
      {
        name: 'Fetal Heart Screening for Congenital Heart Disease (CHD) CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856679',
      },
      {
        name: 'Lung Cancer Screening Decision Guide',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=526046047',
      },
      {
        name: 'Minor Head Trauma in Pediatric Patients CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525932539',
      },
      {
        name: 'Prevention of Contrast-Induced Nephropathy (CIN) in Adult Patients Receiving Iodinated Contrast (IoC) (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=520917083',
      },
    ],
  },
  {
    groupName: 'Medicine Specialties CPMs and Related Tools',
    options: [
      {
        name: 'ACS in the ED and Inpatients (Canyons & Desert Regions)',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530420539',
      },
      {
        name: 'Acute Ischemic Stroke: Emergency Manag​​ement CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520500199',
      },
      {
        name: `Acute Traumatic Intracranial Hemorrhage in Adults: The Modified Brain Injury Guidelines (mBIG)`,
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530445522',
      },
      {
        name: 'Asthma CPM',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=520257347',
      },
      {
        name: 'Atrial Fibriliation CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522592305',
      },
      {
        name: 'Bariatric Surgery: Anesthesia for Bariatric Surgery guideline',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=528681060',
      },
      {
        name: 'Bariatric Surgery: Management of Metabolic or Bariatric Surgery (MBS) Patients in the ED guideline (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=528681056',
      },
      {
        name: 'Chronic Insomnia CPM',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530439962',
        subOptions: [
          {
            name: 'Insomnia Pharmacotherapy',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530439964',
          },
          {
            name: 'Prescribing Sedatives and Hypnotics for Insomnia',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530439966',
          },
        ],
      },
      {
        name: 'Chronic Kidney Disease Stage 1-3B Clinical Guideline (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=530425276',
      },
      {
        name: 'Clostridium difficile (C. diff) Infection CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525982172',
      },
      {
        name: 'Ectopic Pregnancy CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520730837',
      },
      {
        name: 'Febrile Infant CPM (PCH pilot 2020) (Internal only)',
        value: 'https://ckr.ihc.com/ckr/Dcmnt?ncid=529814823',
      },
      {
        name: 'Gender-Affirming Hormone Therapy (GAHT) in Adults (Internal only)',
        value: 'https://ckr.ihc.com/ckr/Dcmnt?ncid=530344054',
      },
      {
        name: 'Low Back Pain in the ED CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522982669',
      },
      {
        name: 'Minor Head Trauma in Pediatric Patients CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525932539',
      },
      {
        name: 'Pediatric Traumatic Stress CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529796906',
      },
      {
        name: 'Pneumonia: Community-Acquired (CAP) CPM - Adult',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520102603',
      },
      {
        name: 'Pneumonia: Community-acquired guideline - Pediatric (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529897090',
      },
      {
        name: 'Pregnant Patients in the ED CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521396209',
      },
      {
        name: 'Prophylaxis Venous Thromboembolism (VTE) in Multiple Myeloma (MM) Clinical Guideline (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=530429389',
      },
      {
        name: 'Severe Sepsis and Septic Shock CPM - Adult',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529626993',
        subOptions: [
          {
            name: 'Sepsis bundle worksheet - Adult',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=529835867',
          },
        ],
      },
      {
        name: 'Skin and Soft Tissue Infections (SSTI): in the ED CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525956021',
      },
      {
        name: 'Skin and Soft Tissue Infection (SSTI): Pediatric Patients Over 3 Months CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=527740063',
      },
      {
        name: 'Urinary Tract Infections (UTI) - Adults CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529723399',
      },
      {
        name: 'Venous Thromboembolism (VTE) CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529597630',
      },
    ],
  },
  {
    groupName: 'Musculoskeletal CPMs and Related Tools',
    options: [
      {
        name: 'Care Process Model Overview Presentation​',
        value:
          'https://intermountainhealth.sharepoint.com/sites/IHClinical/Musculoskeletal%20Documents/PreliminaryCPMPresentation_021216.pdf',
      },
      {
        name: 'Emmi Shared Decision Program Implementation CPM',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529658815',
      },
      {
        name: 'Low Back Pain in the ED CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522982669',
      },
      {
        name: 'Social Determinants of Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529732182',
      },
    ],
  },
  {
    groupName: 'Neurosciences CPMs and Related Tools',
    options: [
      {
        name: 'Acute Ischemic Stroke: Emergency Management CPM​​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520500199',
      },
      {
        name: `Acute Traumatic Intracranial Hemorrhage in Adults: The Modified Brain Injury Guidelines (mBIG)`,
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530445522',
      },
      {
        name: 'Concussion CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529572050',
      },
      {
        name: 'Level of Assist Terminology: FIM Instrument (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529309276',
      },
      {
        name: 'Low Back Pain in the ED CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522982669',
      },
      {
        name: 'Mild Cognitive Impairment (MCI) and Dementia CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529309665',
      },
      {
        name: 'Social Determinants of Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529732182',
      },
      {
        name: 'Stroke: Hospital Care and Rehabilitation for Adult Stroke and TIA Patients CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529339925',
      },
      {
        name: 'Stroke: Hospital Care and Rehabilitation CPM Reference List',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529306199',
      },
    ],
  },
  {
    groupName: 'Pain Services CPMs and Related Tools',
    options: [
      {
        name: 'Aromatherapy Adults, Pediatrics',
        value: 'https://procedures.lww.com/lnp/view.do?pId=6415991',
      },
      {
        name: 'Low Back Pain in the ED CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522982669',
      },
      {
        name: 'Prevention of lethal opioid overdose in the community',
        value:
          'https://www.uptodate.com/contents/prevention-of-lethal-opioid-overdose-in-the-community?search=naloxone&sectionRank=1&usage_type=default&anchor=H178806&source=machineLearning&selectedTitle=3~149&display_rank=1#H178806',
      },
      {
        name: 'Social Determinants of Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529732182',
      },
      {
        name: 'Tapering Opioid Pain Medication CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529635092',
      },
    ],
  },
  {
    groupName: 'Pediatric Specialty CPMs and Related Tools',
    options: [
      {
        name: 'Appendicitis CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=528569391',
      },
      {
        name: 'Asthma: Albuterol Treatment - Pediatrics Inpatients',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521418600',
      },
      {
        name: 'Asthma CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520257347',
      },
      {
        name: 'Bivalirudin - Pediatrics: guideline for clinical pharmacists',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856067',
      },
      {
        name: 'Carbon Monoxide Poisoning CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529735825',
      },
      {
        name: 'Choosing Wisely at Intermountain - Pediatric (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=522448814',
      },
      {
        name: 'Diabetes - Pediatric Type 1 CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520451313',
      },
      {
        name: 'Diabetes - Pediatric Type 2 CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529163870',
      },
      {
        name: 'Eating Disorders CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522882792',
      },
      {
        name: 'Febrile Infant CPM (PCH pilot 2020)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529814823',
      },
      {
        name: 'Fetal Heart Screening for Congenital Heart Disease (CHD) CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856679',
      },
      {
        name: 'Lifestyle and Weight Management for Children and Adolescents CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520289819',
      },
      {
        name: 'Minor Head Trauma in Pediatric Patients CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525932539',
      },
      {
        name: 'Otitis Media, Acute - Pediatric CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522927223',
      },
      {
        name: 'Outdoor Air Quality and Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=527926681',
      },
      {
        name: 'Pediatric Traumatic Stress CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529796906',
      },
      {
        name: 'Pediatric Traumatic Stress in Primary Care Settings Road Map (0 to 5 Years Old)',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529796081',
      },
      {
        name: 'Pediatric Traumatic Stress in Primary Care Settings Road Map (6 to 18 Years Old)',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529796052',
      },
      {
        name: 'Pediatric Traumatic Stress in Child Advocacy Centers (CAC) Road Map (6 to 18 Years Old)​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529796073',
      },
      {
        name: 'Pharmacogenomics (PGx) Testing : Pediatric Psychiatry clinical guideline (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529856590',
      },
      {
        name: 'Pneumonia - Community-acquired guideline - Pediatric (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529897090',
      },
      {
        name: 'Sinusitis, Acute - Adult and Pediatric CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522983040',
      },
      {
        name: 'Sinusitis, Acute - Pediatric summary card',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=522983036',
      },
      {
        name: 'Skin and Soft Tissue Infection: Pediatric Patients Over 3 Months CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=527740063',
      },
      {
        name: 'Social Determinants of Health CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529732182',
      },
      {
        name: 'Streptococcal pharyngits - Adult and Pediatric CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525953897',
      },
    ],
  },
  {
    groupName: 'Primary Care CPMs and Related Tools',
    options: [
      {
        name: 'ADHD CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=51078888',
      },
      {
        name: 'Air Quality and Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=527926681',
      },
      {
        name: 'Air Quality and Health CPM Reference List',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=527926684',
      },
      {
        name: 'Asthma CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520257347',
      },
      {
        name: 'Attention Deficit Hyperactivity Disorder (ADHD) CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=51078888',
      },
      {
        name: 'Atrial Fibrillation CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522592305',
      },
      {
        name: 'Bronchitis CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=51061650',
      },
      {
        name: 'Carbon Monoxide Poisoning CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529735825',
      },
      {
        name: 'Choosing Wisely at Intermountain - Adult (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=522448817',
      },
      {
        name: 'Choosing Wisely at Intermountain - Pediatric (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=522448814',
      },
      {
        name: 'Chronic Insomnia CPM',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530439962',
        subOptions: [
          {
            name: 'Insomnia Pharmacotherapy',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530439964',
          },
          {
            name: 'Prescribing Sedatives and Hypnotics for Insomnia',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=530439966',
          },
        ],
      },
      {
        name: 'Chronic Kidney Disease (CKD) CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521395847',
      },
      {
        name: 'Chronic Kidney Disease Stage 1-3B Clinical Guideline (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=530425276',
      },
      {
        name: 'Chronic Obstructive Pulmonary Disease (COPD) CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520442593',
      },
      {
        name: 'Concussion CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529572050',
      },
      {
        name: 'Dementia CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529309665',
      },
      {
        name: 'Depression CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=51061767',
      },

      {
        name: 'Diabetes - Adult CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=51061827',
      },
      {
        name: 'Diabetes - Pediatric Type 1 CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520451313',
      },
      {
        name: 'Diabetes - Pediatric Type 2 CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529163870',
      },
      {
        name: 'Diabetes Prevention Program CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525955852',
      },
      {
        name: 'Emmi Shared Decision Program Implementation CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529658815',
      },
      {
        name: 'Emmi Shared Decision Program Implementation: Program list',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529658818',
      },
      {
        name: 'Gender-affirming Hormone Therapy (GAHT) in Adults CPM',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=530344054',
      },
      {
        name: 'Gender-affirming Hormone Therapy (GAHT) Goals and Social Support checklist',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=530356768',
      },
      {
        name: 'High Blood Pressure Management CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=51061853',
      },
      {
        name: 'Lifestyle and Weight Management - Adult CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520199293',
      },
      {
        name: 'Lifestyle and Weight Management CPM Reference List',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522600749',
      },
      {
        name: 'Lifestyle and Weight Management for Children and Adolescents CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520289819',
      },
      {
        name: 'Low Back Pain in the ED CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522982669',
      },
      {
        name: 'Lung Cancer Screening Decision Guide',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=526046047',
      },
      {
        name: 'Mental Health Integration (MHI) CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=51080953',
      },
      {
        name: 'Metabolic and Bariatric Surgery (MBS): Anesthesia Guideline',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=528681060',
      },
      {
        name: 'Metabolic and Bariatric Surgery (MBS): Patients in the ED Guideline',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=528681056',
      },
      {
        name: 'Metabolic and Bariatric Surgery (MBS) CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=528689542',
      },
      {
        name: 'Metabolic and Bariatric Surgery (MBS) CPM Appendix',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=528689552',
      },
      {
        name: 'Mild Cognitive Impairment (MCI) and Dementia CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529309665',
      },
      {
        name: 'Minor Head Trauma in Pediatric Patients CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525932539',
      },
      {
        name: 'Obstructive Sleep Apnea (OSA) CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520783766',
      },
      {
        name: 'Obstructive Sleep Apnea (OSA) CPM Reference List',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520783769',
      },
      {
        name: 'Otitis Media, Acute - Pediatric CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522927223',
      },
      {
        name: 'Outdoor Air Quality and Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=527926681',
      },
      {
        name: 'Pneumonia: Community Acquired (CAP) CPM - Adult',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520102603',
      },
      {
        name: 'Prevention of lethal opioid overdose in the community',
        value:
          'https://www.uptodate.com/contents/prevention-of-lethal-opioid-overdose-in-the-community?search=naloxone&sectionRank=1&usage_type=default&anchor=H178806&source=machineLearning&selectedTitle=3~149&display_rank=1#H178806',
      },
      {
        name: 'Severe Sepsis and Septic Shock CPM - Adult',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529626993',
        subOptions: [
          {
            name: 'Sepsis bundle worksheet - Adult',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=529835867',
          },
        ],
      },
      {
        name: 'Sinusitis, Acute - Adult and Pediatric CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=522983040',
      },
      {
        name: 'Sinusitis, Acute - Adult Summary Card',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=523316155',
      },
      {
        name: 'Sinusitis, Acute - Pediatric Summary Card',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=522983036',
      },
      {
        name: 'Skin and Soft Tissue Infections (SSTI) in Adults CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525956021',
      },
      {
        name: 'Social Determinants of Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529732182',
      },
      {
        name: 'Streptococcal pharyngitis - Adult and Pediatric CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=525953897',
      },
      {
        name: 'Substance Use Disorder (SUD) CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=526758352',
      },
      {
        name: 'Suicide Prevention CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=526742474',
      },
      {
        name: 'Testosterone Therapy for Men Guideline (Internal only)',
        value: 'https://kr.ihc.com/kr/Dcmnt?ncid=529471245',
      },
      {
        name: 'Urinary Tract Infections (UTI) - Adults CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529723399',
      },
    ],
  },
  {
    groupName: 'Surgical Services CPMs and Related Tools',
    options: [
      {
        name: 'Appendicitis CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=528569391',
      },
      {
        name: 'Appendicitis CPM Bibliography',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=528569392',
      },
      {
        name: 'Antibiotics for the Management of Appendicitis',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=528607464',
      },
      {
        name: 'Capturing Indications for Red Blood Cell Transfusion CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521050205',
      },
      {
        name: 'Geriatric Hip Fracture CPM​ (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529346331',
      },
      {
        name: 'Geriatric Hip Fracture CPM Reference List (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529499936',
      },
      {
        name: 'Low-risk Laparoscopic Cholecystectomy CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529473973',
      },
      {
        name: 'Metabolic and Bariatric Surgery (MBS): Anesthesia Guideline',
        value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=528681060',
      },
      {
        name: 'Metabolic and Bariatric Surgery (MBS) Patients in the ED Guideline​',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=528681056',
      },
      {
        name: 'Metabolic and Bariatric Surgery (MBS) CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=528689542',
      },
      {
        name: 'Metabolic and Bariatric Surgery (MBS) CPM Appendix',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=528689552',
      },
      {
        name: 'Opioid Tapering CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529635092',
      },
      {
        name: 'Prevention of lethal opioid overdose in the community​',
        value:
          'https://www.uptodate.com/contents/prevention-of-lethal-opioid-overdose-in-the-community?search=naloxone&sectionRank=1&usage_type=default&anchor=H178806&source=machineLearning&selectedTitle=3~149&display_rank=1#H178806',
      },
      {
        name: 'Severe Sepsis and Septic Shock CPM - Adult',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529626993',
        subOptions: [
          {
            name: 'Sepsis bundle worksheet - Adult',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=529835867',
          },
        ],
      },
      {
        name: 'Social Determinants of Health CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529732182',
      },
    ],
  },
  {
    groupName: 'Women & Newborns CPMs and Related Tools',
    options: [
      {
        name: 'Ectopic Pregnancy CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=520730837',
      },
      {
        name: 'Elective Labor Induction CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=51061830',
      },
      {
        name: 'Febrile Infant CPM (PCH pilot 2020)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529814823',
      },
      {
        name: 'Hyperemesis Gravidarum CPM (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=51061847',
      },

      {
        name: 'Outdoor Air Quality and Health CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=527926681',
      },
      {
        name: 'Pregnant Patients in the ED CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521396209',
      },
      {
        name: 'Premature Rupture of Membranes (PROM) CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=521522828',
      },
      {
        name: 'Shoulder Dystocia During Delivery (Internal only)',
        value: 'https://kr.ihc.com/ckr/Dcmnt?ncid=529652139',
      },
      {
        name: 'Social Determinants of Health CPM​',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529732182',
      },
      {
        name: 'Urinary Tract Infections (UTI) - Adults CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529723399',
      },
    ],
  },
  {
    groupName: '​​​​​​​​Wound and Hyperbaric CPMS and Tools',
    options: [
      {
        name: 'Carbon Monoxide Poisoning CPM',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529735825',
      },
      {
        name: 'Carbon Monoxide Poisoning Flash Card',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529735944',
      },
      {
        name: 'Severe Sepsis and Septic Shock CPM - Adult',
        value:
          'https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529626993',
        subOptions: [
          {
            name: 'Sepsis bundle worksheet - Adult',
            value: 'https://kr.ihc.com/ckr-ext/Dcmnt?ncid=529835867',
          },
        ],
      },
    ],
  },
]
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  STATES,
}
export const headerColor = '#003366'
export const newPDEColor = '#4A00E2'
